.Projects
{
    position: relative;
}

.Progress
{
    position: sticky;
    top: 0;
    padding-top: 60px;
    background-color: #0C0C1D;
    text-align: center;
    font-size: 30px;
    color: #F216E4;
    z-index: 1;
}

.ProgressBar
{
    height: 10px;
    background-color: #FFF;
}

.ProjectBanner
{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    padding: 0 150px;
    overflow: hidden;
}

.ProjectImage
{
    flex-basis: 50%;
    width: 100%;
    height: 400px;
}

.ProjectImage img
{
    width: 100%;
    object-fit: cover;
    height: inherit;
    border-radius: 20px;
    cursor: pointer;
    object-position: top;
}

.ProjectText
{
    flex-basis: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
}

.ProjectText h1
{
    flex: 1;
    font-size: 40px;
    color: #FFF;
    margin-bottom: 20px;
}

.ProjectText p
{
    flex: 2;
}

.ProjectText button
{
    flex: 1;
    width: 40%;
    padding: 10px 40px;
    border: none;
    border-radius: 10px;
    background-color: #F216E4;
    color: #FFF;
    font-size: 16px;
    cursor: pointer;
}

@media (max-width: 768px)
{
    .Projects
    {
        height: 100%;
    }
    .ProjectBanner
    {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 30px;
    }

    .Progress h1
    {
        font-size: 30px;
        padding: 0 30px;
        margin-bottom: 10px;
    }

    .ProjectText
    {
        gap: 20px;
    }

    .ProjectImage
    {
        margin-top: 200px;
        height: 150px;
    }

    .ProjectText h1
    {
        font-size: 30px;
    }

    .ProjectText button
    {
        width: 60%;
    }
}
