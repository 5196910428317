@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

*
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Raleway', sans-serif;
}

html
{
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth !important;
}

body
{
    background-color: #0C0C1D;
    color: #FFF;
    overflow-x: hidden;
}

a
{
    text-decoration: none;
    color: inherit;
}