section
{
    height: 100vh;
    scroll-snap-align: center;
}

@media (max-width: 768px)
{
    section
    {
        height: 100%;
        scroll-behavior: smooth;
        scroll-snap-align: none;
    }
}