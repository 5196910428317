.Contact
{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 150px;
    gap: 50px;
    position: relative;
}

.ContactText
{
    flex-basis: 45%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.ContactText h1
{
    font-size: 80px;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 80px;
}

.ContactText h3
{
    margin-top: 20px;
}

.ContactText p
{
    font-size: 18px;
    font-weight: 300;
}

.ContactInfo
{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.ContactForm
{
    flex-basis: 45%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.ContactForm input,
.ContactForm textarea
{
    padding: 15px;
    background-color: transparent;
    border: 2px solid #FFF;
    border-radius: 10px;
    outline: none;
    color: #FFF;
}

.ContactForm textarea
{
    resize: none;
    height: 150px;
}

.ContactForm button
{
    padding: 15px;
    border: none;
    border-radius: 5px;
    background-color: #F216E4;
    color: #FFF;
    font-weight: 500;
    cursor: pointer;
}

@media (max-width: 768px) {
    .Contact {
        display: flex;
        flex-direction: column;
        margin-top: 150px;
        padding: 20px 40px;
        height: 100%;
    }

    .ContactText
    {
        display: flex;
        width: 100%;
        align-items: center;
        flex-basis: 100%;
    }

    .ContactText h1
    {
        font-size: 40px;
        line-height: 40px;
    }

    .ContactInfo
    {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-self: flex-start;
    }

}