.Parallax
{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.Parallax h1
{
    font-size: 100px;
}

.Mountains
{
    background-image: url("https://i.imgur.com/U8h4S6x.png");
    background-size: cover;
    background-position: bottom;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
}

.Planets
{
    background-image: url("https://i.imgur.com/iHb2mHE.png");
    background-size: cover;
    background-position: bottom;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
}

.Stars
{
    background-image: url("https://i.imgur.com/vfacdWT.png");
    background-size: cover;
    background-position: bottom;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}

@media (max-width: 768px)
{
    .Parallax
    {
        height: 100vh;
    }

    .Parallax h1
    {
        font-size: 50px;
    }
}