.Services
{
    position: relative;
    background: linear-gradient(180deg, #0C0C1D, #111132);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.TextContainer
{
    flex-basis: 20%;
    align-self: flex-end;
    display: flex;
    align-items: center;
    gap: 20px;
}

.TextContainer hr
{
    width: 300px;
    border: none;
    border-top: 0.5px solid #808080;
}

.TextContainer p
{
    font-size: 18px;
    font-weight: 200;
    text-align: right;
}

.TitleContainer
{
    flex-basis: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Title
{
    display: flex;
    align-items: center;
    gap: 50px;
}

.Title h1
{
    font-size: 65px;
    font-weight: 100;
}

.TitleContainer button
{
    width: 300px;
    height: 100px;
    border-radius: 50px;
    background: linear-gradient(100deg, #5CE1E6, #F216E4);
    border: none;
    font-size: 30px;
    font-weight: 700;
    cursor: pointer;
}

.Title img
{
    width: 300px;
    height: 100px;
    border-radius: 50px;
    object-fit: cover;
}

.ListContainer
{
    flex-basis: 55%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50px 150px;
    gap: 25px;
}

.ListCard
{
    border-radius: 10px;
    height: 100%;
    padding: 35px 40px;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #000;
    border: 2px solid #FFF;
}

.ListCard a
{
    width: 100%;
}

.ListCard button
{
    width: 100%;
    padding: 10px;
    color: #FFF;
    background-color: #F216E4;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

@media (max-width: 768px)
{
    .Services
    {
        height: 100%;
        display: flex;
        padding: 30px 20px;
        gap: 20px;
    }

    .TextContainer
    {
        display: flex;
        align-self: center;
    }

    .TextContainer p
    {
        text-align: center;
        width: 100%;
    }

    .TextContainer hr
    {
        display: none;
    }

    .Title
    {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .Title img
    {
        display: none;
    }

    .Title h1
    {
        font-size: 40px;
    }

    .ListContainer
    {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 20px 40px;
        gap: 30px;
    }

    .ListCard
    {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}