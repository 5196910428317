.Sidebar
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
    color: #000;
    position: absolute;
}

.Panel
{
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 350px;
    background-color: #FFF;
}

.Links
{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    gap: 20px;
}

.Links a
{
    font-size: 2rem;
    text-transform: capitalize;
    text-decoration: none;
    color: #000;
    transition: 0.3s;
}

.Toggle button
{
    z-index: 999;
    position: fixed;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    top: 25px;
    left: 25px;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 768px)
{
    .Sidebar
    {
        display: none;
    }
}