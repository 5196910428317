.Nav
{
    height: 100px;
}

.Container
{
    padding: 0 150px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.Container h1
{
    text-transform: uppercase;
}

.Container img
{
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    cursor: pointer;

}

.Social
{
    display: flex;
    align-items: center;
    gap: 20px;
}

.Social a
{
    cursor: pointer;
    z-index: 1;
}

.Social svg
{
    cursor: pointer;
}

.Social svg:hover > path
{
    fill: #F216E4;
    outline: #F216E4;
}

@media (max-width: 768px)
{
    .Nav
    {
        width: 100%;
    }

    .Container
    {
        padding: 0 20px;
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
}